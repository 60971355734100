<template>
  <div class="container app-content pt-4">
    <Loading v-if="isLoading" />
    <template v-else>
      <!-- Page Header -->
      <PageHeader :title="donor.display_as" />

      <dl class="row mt-4">
        <div class="col-md-6">
          <dl class="row">
            <dt class="col-sm-3">Address</dt>
            <dd class="col-sm-9">
              {{ donor.street }}<br />
              {{ donor.city }}, {{ donor.state }} {{ donor.zip }}
            </dd>

            <dt class="col-sm-3">Email</dt>
            <dd class="col-sm-9">{{ donor.email ? donor.email : '--' }}</dd>

            <dt class="col-sm-3">Phone</dt>
            <dd class="col-sm-9">{{ donor.phone ? donor.phone : '--' }}</dd>
          </dl>
        </div>

        <div class="col-md-6">
          <dl class="row">
            <dt class="col-sm-3">Donor ID</dt>
            <dd class="col-sm-9">{{ donor.donor_id ? donor.donor_id : '--' }}</dd>

            <dt class="col-sm-3">Member ID</dt>
            <dd class="col-sm-9">{{ donor.member_id ? donor.member_id : '--' }}</dd>

            <dt class="col-sm-3">Const ID</dt>
            <dd class="col-sm-9">{{ donor.const_id ? donor.const_id : '--' }}</dd>

            <dt class="col-sm-3">Type</dt>
            <dd class="col-sm-9">{{ upperFirst(donor.type) }}</dd>

            <dt class="col-sm-3">Channel</dt>
            <dd class="col-sm-9">{{ upperFirst(donor.channel) }}</dd>
          </dl>
        </div>
      </dl>

      <DonationsList :donor="donor" />
    </template>
  </div>
</template>

<script>
import * as _ from 'lodash';
import Loading from '../components/Loading';
import DonationsList from '../components/donations/DonationsList';
import PageHeader from '../components/PageHeader';

export default {
  name: 'DonorsShow',
  components: { DonationsList, Loading, PageHeader },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      isLoading: true,
    };
  },
  computed: {
    donor() {
      const donor = this.$store.getters['donors/getCurrentDonor'];
      console.log('pages DonorsShow computed donor', donor);
      return donor;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.isLoading = true;

      this.$store
        .dispatch('donors/get', { id: this.id })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    upperFirst(data) {
      return _.upperFirst(data);
    },
  },
};
</script>

<style scoped lang="scss"></style>
